import request from '@/utils/request'

const api = {
  user: '/admincp/admin/index',
  usercreate: '/admincp/admin/create',
  userupdate: '/admincp/admin/update',
  userinfo: '/admincp/admin/info',
  userDel: '/admincp/admin/delete',
  userDelete: '/admincp/admin/moredel',
  userPassword: '/admincp/user/password'
}

export function FetchList (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.user,
    method: 'post',
    data: parameter
  })
}

export function create (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.usercreate,
    method: 'post',
    data: parameter
  })
}

export function update (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userupdate,
    method: 'post',
    data: parameter
  })
}

export function del (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userDel,
    method: 'post',
    data: parameter
  })
}

export function moreDel (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userDelete,
    method: 'post',
    data: parameter
  })
}

export function getById (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userinfo,
    method: 'post',
    data: parameter
  })
}

export function userPassword (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: api.userPassword,
    method: 'post',
    data: parameter
  })
}
