const getters = {
  isIndex: state => state.app.isIndex,
  isMobile: state => state.app.isMobile,
  lang: state => state.app.lang,
  theme: state => state.app.theme,
  color: state => state.app.color,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  orgId: state => state.user.orgId,
  nickname: state => state.user.name,
  roles: state => state.user.roles,
  addRouters: state => state.permission.addRouters,
  multiTab: state => state.app.multiTab
}

export default getters
