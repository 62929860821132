<template>
  <pro-layout
    v-bind="settings"
    :collapsed="collapsed"
    :handleCollapse="handleCollapse"
    :handleMediaQuery="handleMediaQuery"
    :i18nRender="i18nRender"
    :isMobile="isMobile"
    :mediaQuery="query"
    :menus="menus"
  >
    <template v-slot:menuHeaderRender>
      <div>
        <img v-if="logo==null" alt="logo" class="logo" src="~@/assets/logo.svg">
        <img v-if="logo!=null" alt="logo" class="logo" :src="logo">
        <h1>{{ siteTitle }}</h1>
      </div>
    </template>

    <template v-slot:rightContentRender>
      <span class="headerItem " @click="handpartalClick(index)" v-for="(value,index) in toplistMenu">
        <span :class="index === currentTab?'selctedNav':''">{{ value.meta.title }}</span>
      </span>
      <right-content :is-mobile="isMobile" :theme="settings.theme" :top-menu="settings.layout === 'topmenu'"/>
    </template>
    <template v-slot:footerRender>
      <global-footer/>
    </template>
    <router-view/>
  </pro-layout>
</template>

<script>
import { i18nRender } from '@/locales'
import { mapState } from 'vuex'
import { CONTENT_WIDTH_TYPE, SIDEBAR_TYPE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'

import defaultSettings from '@/config/defaultSettings'
import RightContent from '@/components/GlobalHeader/RightContent'
import GlobalFooter from '@/components/GlobalFooter'
import Ads from '@/components/Other/CarbonAds'
import { publicInfo } from '@/api/public'

export default {
  name: 'BasicLayout',
  components: {
    RightContent,
    GlobalFooter,
    Ads
  },
  data () {
    return {
      // preview.pro.antdv.com only use.
      isProPreviewSite: process.env.VUE_APP_PREVIEW === 'true' && process.env.NODE_ENV !== 'development',
      // end
      currentTab: 0,
      // base
      menus: [],
      toplistMenu: [],
      menusdata: [],
      // 侧栏收起状态
      collapsed: false,
      siteTitle: defaultSettings.title,
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // CONTENT_WIDTH_TYPE
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,

        hideHintAlert: false,
        hideCopyButton: false
      },
      // 媒体查询
      query: {},

      // 是否手机模式
      isMobile: false,
      logo: null
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: state => state.permission.addRouters
    })
  },
  created () {
    const routes = this.mainMenu.find(item => item.path === '/')
    this.menusdata = (routes && routes.children) || []
    this.toplistMenu = this.menusdata
    let isIndex = 0
    if (sessionStorage.getItem('indexParal')) {
      isIndex = Number(sessionStorage.getItem('indexParal'))
      this.currentTab = isIndex
    }
    this.menus = this.menusdata[isIndex].children

    // // 处理侧栏收起状态
    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    })
    this.siteInfo()
  },
  mounted () {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }
  },
  methods: {
    i18nRender,
    handleMediaQuery (val) {
      this.query = val
      if (this.isMobile && !val['screen-xs']) {
        this.isMobile = false
        return
      }
      if (!this.isMobile && val['screen-xs']) {
        this.isMobile = true
        this.collapsed = false
        this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
        // this.settings.fixSiderbar = false
      }
    },
    handleCollapse (val) {
      this.collapsed = val
    },
    handpartalClick  (index) {
      console.log(index)
      const self = this
      sessionStorage.setItem('indexParal', index)
      self.menus = this.toplistMenu[index].children
      self.$forceUpdate()
      this.currentTab = index
      if (self.toplistMenu[index].path === '') {
        this.$router.replace({ 'path': self.toplistMenu[index].children[0].path })
      } else {
        this.$router.replace({ 'path': self.toplistMenu[index].path })
      }
    },
    siteInfo () {
      publicInfo()
        .then(res => {
          if (res.code === 1) {
            const params = res.result
             this.siteTitle = process.env.VUE_APP_SITE_TITLE

            if (params.logo != null) {
              this.logo = '/web/attr/show?attr_id=' + params.logo
            }
          } else {
            this.siteTitle = process.env.VUE_APP_SITE_TITLE
          }
        })
    }
  }
}
</script>

<style lang="less">
@import "./BasicLayout.less";
.ant-pro-global-header .headerItem{
  padding: 4px;
  margin: 2px 10px;
}
.selctedNav{
  background-color: #1890ff;
  padding: 10px;
  color:#FFFFFF;

}
</style>
