import request from '@/utils/request'

const userApi = {
  Login: '/pub/auth/login',
  Logout: '/pub/auth/logout',
  AuthCaptcha: '/pub/auth/captcha',
  UserInfo: '/admincp/user/info',
  UserMenu: '/admincp/menu/pub'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: userApi.Login,
    method: 'post',
    data: parameter
  })
}

export function getCaptcha (parameter) {
  if (parameter === undefined) {
    parameter = {}
  }
  return request({
    url: userApi.AuthCaptcha,
    method: 'post',
    responseType: 'blob',
    data: parameter
  })
}

export function getInfo () {
  return request({
    url: userApi.UserInfo,
    method: 'post'
  })
}

export function getCurrentUserNav () {
  return request({
    url: userApi.UserMenu,
    method: 'get'
  })
}

export function logout () {
  return request({
    url: userApi.Logout,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
