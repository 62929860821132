import storage from 'store'
import { getInfo, login, logout } from '@/api/login'
import { ACCESS_TOKEN, ORGID } from '@/store/mutation-types'

const user = {
  state: {
    token: '',
    orgId: '',
    name: '',
    roles: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_ORGID: (state, orgId) => {
      state.orgId = orgId
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (response.code === 1) {
            const result = response.result
            storage.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
            sessionStorage.setItem(ACCESS_TOKEN, result.token)
            commit('SET_TOKEN', result.token)
            storage.set(ORGID, result.orgId, 7 * 24 * 60 * 60 * 1000)
            sessionStorage.setItem(ORGID, result.orgId)
            commit('SET_ORGID', result.orgId)
            resolve()
          } else {
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const result = response.result
          if (result.role && result.role.permissions.length > 0) {
              const role = result.role
              role.permissions = result.role.permissions
              role.permissions.map(per => {
                if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
                  const action = per.actionEntitySet.map(action => { return action.action })
                  per.actionList = action
                }
              })
              role.permissionList = role.permissions.map(permission => { return permission.permissionId })
            commit('SET_ROLES', result.role)
          } else {
            reject(new Error('getInfo: roles must be a non-null array !'))
          }
          commit('SET_NAME', result.name)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          commit('SET_ROLES', [])
          storage.remove(ACCESS_TOKEN)
          commit('SET_ORGID', undefined)
          storage.remove(ORGID)
          sessionStorage.clear()
          resolve()
        }).catch(() => {
          storage.remove(ACCESS_TOKEN)
          resolve()
        }).finally(() => {
        })
      })
    }

  }
}

export default user
