<template>
  <span>
    <a-modal v-model="visible" title="修改密码" on-ok="handleOk">
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          返回
        </a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
          提交
        </a-button>
      </template>
      <a-form
        :form="form"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol">
        <a-form-item label="密码">
          <a-input
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: '密码',
                  },
                  {
                    validator: validateToNextPassword,
                  },
                  { min: 4,max:30, message: '请输入6-30位字符' }
                ],
              },
            ]"
            type="password"
          />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input
            v-decorator="[
              'confirm',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入确认密码',
                  },
                  {
                    validator: compareToFirstPassword,
                  }
                ],
              },
            ]"
            type="password"
            @blur="handleConfirmBlur"
          />
        </a-form-item>
      </a-form>

    </a-modal>

    <a-dropdown v-if="currentUser && currentUser.name" placement="bottomRight">
      <span class="ant-pro-account-avatar">
        <img src="~@/assets/avatar2.jpg" width="20" height="20" class="antd-pro-global-header-index-avatar avatar">
        <span>{{ currentUser.name }}</span>
      </span>
      <template v-slot:overlay>
        <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
          <a-menu-divider v-if="menu"/>
          <a-menu-item v-if="menu" key="center" @click="handleToPass">
            <a-icon type="user"/>
            修改密码
          </a-menu-item>
          <a-menu-divider v-if="menu"/>
          <a-menu-item key="logout" @click="handleLogout">
            <a-icon type="logout"/>
            退出登录
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>

    <span v-else>
      <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }"/>
    </span>
  </span>
</template>

<script>
import { Modal, TreeSelect } from 'ant-design-vue'
import { userPassword } from '@/api/admin'
export default {
  name: 'AvatarDropdown',
  components: {
    TreeSelect
  },
  props: {
    currentUser: {
      type: Object,
      default: () => null
    },
    menu: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      orgvisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      loading: false,
      visible: false,
      confirmDirty: false,
      form: this.$form.createForm(this),
      formorg: this.$form.createForm(this),
      treeData: [],
      replaceFields: { children: 'children', title: 'name', key: 'orgId', value: 'orgId' }
    }
    },
    methods: {
      handleToPass () {
        this.visible = true
      },
      handleLogout (e) {
        Modal.confirm({
          title: this.$t('layouts.usermenu.dialog.title'),
          content: this.$t('layouts.usermenu.dialog.content'),
          onOk: () => {
            return this.$store.dispatch('Logout').then(() => {
              this.$router.push({ name: 'login' })
            })
          },
          onCancel () {
          }
        })
      },
      handleOk (e) {
        this.loading = true
        const self = this
        this.form.validateFields((errors, values) => {
          if (!errors) {
            userPassword(values)
              .then(res => {
                if (res.code === 1) {
                  self.loading = false
                  self.visible = false
                  self.$message.info('修改成功')
                } else {
                  self.$message.error(res.message)
                }
              })
          }
        })
      },
      handleCancel (e) {
        this.visible = false
      },
      compareToFirstPassword (rule, value, callback) {
        const form = this.form
        if (value && value !== form.getFieldValue('password')) {
          // eslint-disable-next-line standard/no-callback-literal
          callback('两次输入密码不一致')
        } else {
          callback()
        }
      },
      validateToNextPassword (rule, value, callback) {
        const form = this.form
        if (value && this.confirmDirty) {
          form.validateFields(['confirm'], { force: true })
        }
        callback()
      },
      handleConfirmBlur (e) {
        const value = e.target.value
        this.confirmDirty = this.confirmDirty || !!value
      }
    }
  }
</script>

<style lang="less" scoped>
.avatar{
  border-radius: 10px;
}
</style>
