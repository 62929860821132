<template>
  <div id="userLayout" :class="['user-layout-wrapper', isMobile && 'mobile']">
    <div class="container">
      <div class="top">
        <div class="header">
          <a href="/">

            <img v-if="logo==null" alt="logo" class="logo" src="~@/assets/logo.svg">
            <img v-if="logo!=null" alt="logo" class="logo" :src="logo">
            <span class="title" v-html="siteTitle"></span>
          </a>
        </div>
        <div class="desc"></div>
      </div>
      <router-view/>
      <div class="footer">
        <div class="copyright">
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo" style="color: #fff"><img :src="gongan"/> </a>京公网安备 11010102005766号<br/> 版权所有  {{ copyright }} 备案号：{{ recordNumber }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'
import { publicInfo } from '@/api/public'

export default {
  name: 'UserLayout',
  mixins: [deviceMixin],
  data () {
    return {
      siteTitle: '',
      copyright: '',
      team: '',
      logo: null,
      recordNumber: null,
      gongan: '/statics/img/gongan.png'
    }
  },
  mounted () {
    document.body.classList.add('userLayout')
  },
  beforeDestroy () {
    document.body.classList.remove('userLayout')
  },
  created () {
    this.siteInfo()
  },
  methods: {
    siteInfo () {
      publicInfo()
        .then(res => {
          if (res.code === 1) {
            const params = res.result
            if (params.siteName == null) {
              this.siteTitle = process.env.VUE_APP_SITE_TITLE
            } else {
              this.siteTitle = params.siteName
            }
            if (params.logo != null) {
              this.logo = '/web/attr/show?attr_id=' + params.logo
            }
            this.copyright = params.copyright
            this.team = process.env.VUE_APP_SITE_TEAM
            this.recordNumber = params.recordNumber
          } else {
            this.siteTitle = process.env.VUE_APP_SITE_TITLE
            this.copyright = process.env.VUE_APP_SITE_COPYRIGHT
            this.team = process.env.VUE_APP_SITE_TEAM
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
  height: 100%;

  &.mobile {
    .container {
      .main {
        max-width: 368px;
        width: 98%;
      }
    }
  }

  .container {
    width: 100%;
    min-height: 100%;
    background: #f0f2f5 url(~@/assets/background.png) no-repeat 50%;
    background-size: 100% 100%;
    padding: 110px 0 144px;
    position: relative;

    a {
      text-decoration: none;
    }

    .top {
      text-align: center;

      .header {
        height: 44px;
        line-height: 44px;

        .badge {
          position: absolute;
          display: inline-block;
          line-height: 1;
          vertical-align: middle;
          margin-left: -12px;
          margin-top: -10px;
          opacity: 0.8;
        }

        .logo {
          height: 44px;
          vertical-align: top;
          margin-right: 16px;
          border-style: none;
        }

        .title {
          font-size: 33px;
          color: rgba(0, 0, 0, .85);
          font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
          font-weight: 600;
          position: relative;
          color: #ffffff;
          top: 2px;
        }
      }

      .desc {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 12px;
        margin-bottom: 40px;
      }
    }

    .main {

      min-width: 260px;
      width: 500px;
      margin: 100px auto;
    }

    .LoginWin {
      background-color: white;
      padding: 40px;
      border-radius: 10px;
    }

    .footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 0 16px;
      margin: 48px 0 24px;
      text-align: center;

      .links {
        margin-bottom: 8px;
        font-size: 14px;

        a {
          color: rgba(0, 0, 0, 0.45);
          transition: all 0.3s;

          &:not(:last-child) {
            margin-right: 40px;
          }
        }
      }

      .copyright {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
}
</style>
