import antd from 'ant-design-vue/es/locale-provider/zh_CN'
import momentCN from 'moment/locale/zh-cn'

const components = {
  antLocale: antd,
  momentName: 'zh-cn',
  momentLocale: momentCN
}

const locale = {
  'app.setting.pagestyle': '设置页面样式',
  'app.setting.themecolor': '设置样式颜色',
  'app.setting.navigationmode': '设置导航模式',
  'app.setting.content-width': '设置内容宽度',
  'app.setting.fixedheader': '固定标题',
  'app.setting.fixedsidebar': '固定侧边栏',
  'app.setting.othersettings': '其他设置',
  'app.setting.weakmode': '设置夜间模式',
  'app.setting.production.hint': '提示',
  'menu.advertisement.manage': '广告管理',
  'menu.advertisement.list': '广告列表',
  'menu.advertisement.space': '广告位',
  'menu.advertisement.category': '广告分类',
  'app.setting.copy': '复制',
  'message': '-',
  'menu.home': '首页',
  'menu.dashboard': '欢迎页',
  'menu.admin.user': '用户管理',
  'menu.admin.role': '角色管理',
  'menu.admin.permission': '权限管理',
  'menu.admin.admin': '管理员管理',
  'menu.system.dictionary': '数据字典',
  'menu.system.region': '地区管理',
  'menu.system.attachment': '附件管理',
  'menu.system.attachment.list': '图片列表',
  'menu.system.attachment.video': '视频列表',
  'menu.system.attachment.file': '文件列表',
  'menu.system.badword': '词汇过滤',
  'menu.system.industry': '行业管理',
  'menu.system.linker': '友情链接',
  'menu.system.site': '站点管理',
  'menu.system.menu': '菜单管理',
  'menu.statistics': '统计分析',
  'menu.statistics.stuser': '用户统计',
  'menu.statistics.storder': '订单统计',
  'menu.statistics.starticle': '文章统计',
  'menu.statistics.stfinance': '财务统计',
  'menu.article.manage': '文章管理',
  'menu.article.list': '文章列表',
  'menu.article.comment': '文章评论',
  'menu.article.category': '文章分类',
  'menu.organization': '组织机构',
  'layouts.usermenu.dialog.title': '退出提醒！',
  'layouts.usermenu.dialog.content': '确定要唐退出系统吗？',
  'menu.wechat': '微信管理',
  'menu.wechat.wechatuser': '粉丝列表',
  'menu.wechat.wechatgroup': '粉丝分组管理',
  'menu.wechat.wechattags': '粉丝标签管理',
  'menu.advert.manage': '广告管理',
  'menu.advert.spaceList': '广告位管理',
  'menu.advert.list': '广告信息列表',
  'menu.goods.manage': '商品管理',
  'menu.goods.goodsList': '商品列表',
  'menu.goods.goodsAttr': '商品属性',
  'menu.goods.goodsCatecory': '商品分类',
  'menu.goods.goodsBrand': '商品品牌',
  'menu.goods.goodsType': '商品类型',
  'menu.enterprise.manage': '供应商管理',
  'menu.enterprise.user.list': '供应信息',
  'menu.order.manage': '订单管理',

  'menu.goodsorder.list': '商品订单列表',
  'menu.menbercard.category': '会员卡订单',
  'menu.finance.manage': '财务管理',
  'menu.transactionRecords.list': '支付订单',
  'menu.refundOrder.list': '退款订单',
  'menu.withdrawal.list': '提现订单',
  'menu.points.mall.goodslist': '积分商品',
  'menu.group.mall.goodslist': '团购商品',
  'menu.coupon.manage': '优惠券管理',
  'menu.coupon.list': '优惠券列表'

}

export default {
  ...components,
  ...locale
}
