<template>
  <global-footer class="footer custom-render">
    <template v-slot:links>
    </template>
    <template v-slot:copyright>
      <a href="javascript:void(0)" target="_blank">{{ copyright }}</a>
    </template>
  </global-footer>
</template>

<script>
import { GlobalFooter } from '@ant-design-vue/pro-layout'
import { publicInfo } from '@/api/public'
export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  },
  data () {
    return {
      copyright: process.env.VUE_APP_SITE_COPYRIGHT
    }
  },
  created () {
    this.siteInfo()
  },
  methods: {
    siteInfo () {
      publicInfo()
        .then(res => {
          if (res.code === 1) {
            const params = res.result
            if (params.copyright != null) {
              this.copyright = params.copyright
            }
          } else {
            this.copyright = process.env.VUE_APP_SITE_COPYRIGHT
          }
        })
    }
  }
}
</script>
